import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Navbar from './navbar'
import Footer from './footer'
import Loader from './loader'
import { GlobalContextProvider } from '../context/global'
import { ThemeContextProvider } from '../context/theme'
import { LocationContextProvider } from '../context/location'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`

const Base = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.xxl}px) {
    max-width: ${props => props.theme.breakpoints.xxl}px;
    margin: auto;
  }
`

const Main = styled.main`
  min-height: 100vh;
`

const Layout = (props) => {
  const { children, location } = props
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [isLoading])

  return (
    <GlobalContextProvider>
      <LocationContextProvider location={props.location}>
        <ThemeContextProvider>
          <Base>
            <GlobalStyle />
            <Loader isShow={isLoading} />
            <Navbar />
            <Main>
              { children }
            </Main>
            <Footer />
          </Base>
        </ThemeContextProvider>
      </LocationContextProvider>
    </GlobalContextProvider>
  )
}

export default Layout