import styled from 'styled-components'

const Section = styled.section`
  ${props => {
    if (props.padded) {
      return `
        padding-top: ${props.theme.spacing * 4}px;
        padding-bottom: ${props.theme.spacing * 4}px;
        padding-left: ${props.theme.spacing * 2}px;
        padding-right: ${props.theme.spacing * 2}px;
      `
    } else {
      return `padding: 0px;`
    }
  }}
  background-color: ${props => props.inverted && props.theme.palette.primary.contrast};
  box-sizing: border-box;
`

export default Section