export default {
  palette: {
    primary: {
      main: '#ffffff',
      semiDarkest: '#025002',
      semiDarker: '#036303',
      lighter: '#88fb88',
      lightest: '#d8fed8',
      contrast: '#013101',
      disabled: '#a7a7a7',
    },
  },
  breakpoints: {
    xs: 320,
    sm: 475,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
  },
  spacing: 8,
  typography: {
    fontSize: 16,
    fontFamily: [
      'Calibri',
      'sans-serif'
    ].join(','),
    variant: {
      h1: {
        fontSize: '2rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.75rem',
        fontWeight: 700,
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 700,
      },
      h4: {
        fontSize: '1.375rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      h6: {
        fontSize: '1.125rem',
        fontWeight: 600,
      },
      body: {
        fontSize: '1rem',
        fontWeight: 500,
      },
      caption: {
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      nav: {
        fontSize: '1.25rem',
        fontWeight: 600,
      }
    }
  },
  navbar: {
    height: 40,
  }
}