import React, { useReducer, createContext } from 'react'
import { CHANGE_THEME } from '../actions/theme'
import { SET_DONE } from '../actions/loading'

export const GlobalContext = createContext()

const initialState = {
  theme: 'inverted',
  isDone: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      }
    case SET_DONE:
      return {
        ...state,
        isDone: action.payload.isDone,
      }
    default:
      throw new Error("Action type did not recognized.")
  }
}

export const GlobalContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      { props.children }
    </GlobalContext.Provider>
  )
}