import React, { createContext } from 'react'

export const LocationContext = createContext()

export const LocationContextProvider = (props) => {
  const { location, children } = props

  return (
    <LocationContext.Provider value={location}>
      { children }
    </LocationContext.Provider>
  )
}