import React, { useContext } from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalContext } from './global'
import theme from '../theme'

export const ThemeContextProvider = (props) => {
  const { children } = props
  const [state, dispatch] = useContext(GlobalContext)

  return (
    <ThemeProvider theme={theme[state.theme]}>
      { children }
    </ThemeProvider>
  )
}

export const themeDecorator = (theme) => (storyFn) => (
  <ThemeProvider theme={theme}>
    {storyFn()}
  </ThemeProvider>
)