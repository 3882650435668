import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { GiHamburgerMenu } from 'react-icons/gi'
import { GrClose } from 'react-icons/gr'
import { useSpring, animated, config } from 'react-spring'
import styled from 'styled-components'
import useMedia from '../hooks/useMedia'
import { LocationContext } from '../context/location'

const Header = styled.header`
  background-color: ${props => props.theme.palette.primary.main};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.palette.primary.contrast};
  padding-top: ${props => props.theme.spacing * 1}px;
  padding-bottom: ${props => props.theme.spacing * 1}px;
  box-shadow: 0px 2px 10px -4px rgba(0,0,0,0.3);
  position: fixed;
  z-index: 999;

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    position: relative;
    box-shadow: unset;
    padding: unset;
    min-height: 102px;
    justify-content: space-between;
  }
`

const BurgerMenuToOpen = styled.button`
  position: absolute;
  left: ${props => props.theme.spacing * 2}px;
  color: ${props => props.theme.palette.primary.contrast};
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: block;
  
  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

const BurgerMenuToClose = styled.button`
  position: absolute;
  top: ${props => props.theme.spacing * 2}px;
  left: ${props => props.theme.spacing * 2}px;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: block;

  path {
    stroke: ${props => props.theme.palette.primary.main};
  }
  
  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    display: none;
  }
`

const Branding = styled.div`
  height: 40px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${props => props.theme.spacing * 2}px;

  img {
    height: 100%;
    width: auto;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    height: 90px;
    margin-left: ${props => props.theme.spacing * 8}px;
  }
`

const Nav = styled(animated.nav)`
  font-size: ${props => props.theme.typography.variant.nav.fontSize};
  font-weight: ${props => props.theme.typography.variant.nav.fontWeight};
  letter-spacing: 0.125rem;

  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.primary.contrast};
  color: ${props => props.theme.palette.primary.main};

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    position: relative;
    height: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrast};
  }
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  margin-left: ${props => props.theme.spacing * 4}px;
  margin-right: ${props => props.theme.spacing * 4}px;
  padding-top: ${props => props.theme.spacing}px;
  padding-bottom: ${props => props.theme.spacing}px;
  position: relative;

  transition: all 0.3s;

  :hover {
    &:after {
      right: 0;
    }
  }

  :after {
    transition: right 0.3s;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    height: 2px;
    background-color: ${props => props.theme.palette.primary.contrast};
  }
`

const Navbar = (props) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const location = useContext(LocationContext)
  const device = useMedia()
  const navSpringAnimation = useSpring({
    from: {
      top: '-100vh',
    },
    to: {
      top: isMobileNavOpen ? '0vh' : '-100vh',
    }
  })

  useEffect(() => {
    setMobileNavOpen(false)
  }, [location.hash])
  
  const handleClick = (e) => setMobileNavOpen(!isMobileNavOpen)

  return (
    <Header>
      <BurgerMenuToOpen onClick={handleClick}>
        <GiHamburgerMenu />
      </BurgerMenuToOpen>
      <Branding>
        <img src="/img/logo.png" alt="ESIMCA Corporation" />
      </Branding>
      {
        (Boolean(device) && (device === 'Desktop' || device === 'Desktop-XL' || device === 'Desktop-XXL')) &&
        <Nav>
          <BurgerMenuToClose onClick={handleClick}>
            <GrClose />
          </BurgerMenuToClose>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/#about">About</NavLink>
          <NavLink to="/#services">Services</NavLink>
          <NavLink to="/#projects">Projects</NavLink>
          <NavLink to="/#contact">Contact</NavLink>
        </Nav>
      }
      {
        (Boolean(device) && (device === 'Mobile-XS' || device === 'Mobile' || device === 'Tablet')) &&
        <Nav style={navSpringAnimation}>
          <BurgerMenuToClose onClick={handleClick}>
            <GrClose />
          </BurgerMenuToClose>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/#about">About</NavLink>
          <NavLink to="/#services">Services</NavLink>
          <NavLink to="/#projects">Projects</NavLink>
          <NavLink to="/#contact">Contact</NavLink>
        </Nav>
      }
    </Header>
  )
}

export default Navbar
