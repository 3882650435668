import React, { useEffect, useState, useContext } from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import SpinnerLoader from "react-spinners/MoonLoader"
import useTheme from '../hooks/useTheme'
import { GlobalContext } from '../context/global'
import { actionSetDone } from '../actions/loading'

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.primary.main};
  z-index: 999;
  opacity: 1;
  
  transition: all 0.5s;

  &.hide {
    opacity: 0;
  }

  &.remove {
    display: none;
  }
`

const delay = 1000

const Loader = ({ isShow }) => {
  const theme = useTheme()
  const [isRemove, setRemove] = useState(false)
  const [state, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    if (state.isDone) {
      setTimeout(() => {
        setRemove(true)
      }, 500)
    }
  }, [state.isDone])

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        dispatch(actionSetDone(true))
      }, [delay])
    }
  }, [isShow, dispatch])

  return (
    <LoaderContainer className={clsx(state.isDone && 'hide', isRemove && 'remove')}>
      <SpinnerLoader
        size={50}
        color={theme.palette.primary.contrast}
        loading={!state.isDone}
      />
    </LoaderContainer>
  )
}

export default Loader

