import { useState, useEffect } from 'react'
import useTheme from './useTheme'

const getDeviceByWidth = (width, breakpoints) => {
  if (width === null) return null;

  if (width < breakpoints.xs) {
    return 'Mobile-XS'
  } else if (width >= breakpoints.xs && width < breakpoints.sm) {
    return 'Mobile'
  } else if (width >= breakpoints.sm && width < breakpoints.md) {
    return 'Tablet'
  } else if (width >= breakpoints.md && width < breakpoints.lg) {
    return 'Desktop'
  } else if (width >= breakpoints.lg && width < breakpoints.xl) {
    return 'Desktop-XL'
  } else if (width >= breakpoints.xl) {
    return 'Desktop-XXL'
  }
}

export default function useMedia() {
  const hasWindow = typeof window !== 'undefined'

  // theme hook
  const theme = useTheme()

  // width hook
  const [width, setWidth] = useState(hasWindow ? window.innerWidth : null)
  useEffect(() => {
    if (hasWindow) {
      window.addEventListener("resize", () => {
        setWidth(window.innerWidth)
      })
    }
  })

  // device hook
  const defaultDevice = getDeviceByWidth(width, theme.breakpoints)
  const [device, setDevice] = useState(defaultDevice)
  useEffect(() => {
    const currentDevice = getDeviceByWidth(width, theme.breakpoints)
    setDevice(currentDevice)
  }, [width])

  return device;
}