import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  display: block;
  border-top: 1px solid #dbdbdb;
  margin-top: ${props => props.theme.spacing * 4}px;
  padding: ${props => props.theme.spacing * 4}px;  

  @media screen and (min-width: ${props => props.theme.breakpoints.md}px) {
    margin-top: ${props => props.theme.spacing * 8}px;
    padding-left: ${props => props.theme.spacing * 8}px;
    padding-right: ${props => props.theme.spacing * 8}px;
    background-color: ${props => props.theme.palette.primary.main};
    min-height: 150px;
    display: grid;
    grid-template-columns: auto 30% 30%;
    grid-template-rows: auto auto;
    grid-template-areas: 
      "branding pages projects"
      "bottom bottom bottom";
  }
`

const Branding = styled.div`
  grid-area: branding;
  display: block;
`

const Pages = styled.div`
  grid-area: pages;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Projects = styled.div`
  grid-area: projects;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const Bottom = styled.div`
  grid-area: bottom;
  display: block;
  padding-top: ${props => props.theme.spacing * 4}px;
  padding-bottom: ${props => props.theme.spacing * 4}px;
`

const Logo = styled.img`
  height: 100%;
  width: auto;
  margin-bottom: ${props => props.theme.spacing * 4}px;
`

const LogoContainer = styled.div`
  height: 90px;
`

const Info = styled.p`
  color: ${props => props.theme.palette.primary.contrast};
  font-size: 1.25rem;
`

const TextLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.contrast};
  display: block;
  margin-bottom: ${props => props.theme.spacing * 1}px;

  span {
    margin-right: ${props => props.theme.spacing * 2}px;
  }
`

const ActionLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.palette.primary.contrast};
  margin-bottom: ${props => props.theme.spacing * 2}px;
  position: relative;
  width: auto;

  :hover {
    &:after {
      right: 0;
    }
  }

  :after {
    transition: right 0.3s;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    height: 2px;
    background-color: ${props => props.theme.palette.primary.contrast};
  }
`

const Header = styled.h2`
  font-size: 2rem;
  color: ${props => props.theme.palette.primary.contrast};
  margin-bottom: ${props => props.theme.spacing * 6}px;
`

const Footer = () => {
  return (
    <FooterContainer>
      <Branding>
        <LogoContainer>
          <Logo src="/img/logo.png" alt="ESIMCA Corporation Logo" />
        </LogoContainer>
        <Info>
          San Isidro Quezon City
        </Info>
        <Info>
          <TextLink href="mailto:info.esimca@gmail.com"><span>E:</span> info.esimca@gmail.com</TextLink>
          <TextLink href="tel:+639398853408"><span>M:</span> +63 9398 853 408</TextLink>
          <TextLink href="tel:+639534050618"><span>M:</span> +63 9534 050 618</TextLink>
        </Info>
      </Branding>
      <Pages>
        <Header>Browse</Header>
        <ActionLink to="/">Home</ActionLink>
        <ActionLink to="/#about">About</ActionLink>
        <ActionLink to="/#services">Services</ActionLink>
        <ActionLink to="/#projects">Projects</ActionLink>
      </Pages>
      <Projects>
        <Header>Services</Header>
        <ActionLink to="/services/engineering-designs">Engineering Designs</ActionLink>
        <ActionLink to="/services/as-built-plans-preparations">As-Built Plans Preparations</ActionLink>
        <ActionLink to="/services/build">Build</ActionLink>
        <ActionLink to="/services/project-management">Project Management</ActionLink>
        <ActionLink to="/services/electrical-system-maintenance-and-testing">Electrical System Maintenance and Testing</ActionLink>
        <ActionLink to="/services/consultancy">Consultancy</ActionLink>
        <ActionLink to="/services/trading">Trading</ActionLink>
      </Projects>
      <Bottom>
        Copyright © 2021 ESIMCA Corporation. Created by <ActionLink href="https://brianpunzalan.com">Brian Punzalan</ActionLink>
      </Bottom>
    </FooterContainer>
  )
}

export default Footer
